<template>
    <div class="guide-article btm-menu">
        <Breadcrumb :background="true" :content="title" :backFocus="true" />

        <div class="section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="guide-article__top">
                            <div class="guide-article__top-img">
                                <img :src="image" :alt="category">
                            </div>
                            <div class="guide-article__top-info">
                                <h2>{{ category }}</h2>
                                <h3 class="d-lg-none">{{ getCategoryDescription(category) }}</h3>
                                <h3 class="d-none d-lg-block">{{ getCategoryDescription(category) }}</h3>
                            </div>
                        </div>

                        <div class="mb30">
                            <div class="form-group">
                                <span class="material-icons" aria-hidden="true">search</span>
                                <input type="text" class="form-control icon-left" v-model="searchWord"
                                    placeholder="Gib einen Suchbegriff ein ...">
                            </div>
                        </div>

                        <div class="anchor-box d-lg-block mb40" v-if="categoryContentList !== null">
                            <h3>Kategorien</h3>
                            <ul class="anchor-links">
                                <li v-for="(group, index) in categoryContentList" :key="group.id">
                                    <button @click="scrollToAnchor(normalizeString(group.category))"
                                        :title="'Link zur Kategorie: ' + group.category"
                                        class="no-btn btn-link">{{ group.category }}</button>
                                </li>
                                <li v-if="$isMobileScreen"><button @click="scrollToAnchor('helpful-links-anchor')"
                                        title="Hilfreiche Links" class="no-btn btn-link">Hilfreiche Links</button></li>
                            </ul>
                        </div>

                        <div class="helpful d-none d-lg-block" v-if="hilfreicheLinks != null">
                            <h3>Hilfreiche Links</h3>
                            <ul class="helpful-links">
                                <HelpfulLinks v-for="(item, index) in hilfreicheLinks" :key="item.id" :link="item" />
                            </ul>
                        </div>

                    </div>

                    <div class="col-12 col-lg-8 col-xl-8 ml-auto main-content-section" v-if="dataSet.total > 0">
                        <div v-for="(group, index) in categoryContentList" :key="group.category"
                            :class="index !== 0 ? 'mt30' : ''">
                            <div v-if="$isMobileScreen" class="group-box" @click="group.collapsed = !group.collapsed">
                                <h4 :id="normalizeString(group.category)" class="text-uppercase mb20">{{ group.category }}
                                </h4>
                                <span class="collapsible-button material-icons" aria-hidden="true">{{ group.collapsed ?
                                    'expand_more' : 'expand_less' }}</span>
                            </div>
                            <h4 v-if="!$isMobileScreen" :id="normalizeString(group.category)"
                                class="text-uppercase group-label mb20">{{ group.category }}</h4>


                            <div class="row" v-show="!group.collapsed">
                                <div class="col-12 col-md-6 col-xl-4 d-flex align-items-stretch"
                                    v-for="content in group.contents" :key="content.id">
                                    <ratgeber-card class="ratgeber-in-category" :category="category"
                                        :content="content" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="col-12 col-lg-8 col-xl-8 ml-auto main-content-section search-no-results">
                        <img src="/assets/search-no-result.png" alt="Keine Suchergebnisse">
                        <h5 class="mt20">Keine Ergebnisse gefunden</h5>
                    </div>

                    <div class="col-12 d-lg-none mt20" v-if="hilfreicheLinks != null">
                        <div class="helpful">
                            <h3 id="helpful-links-anchor">Hilfreiche Links</h3>
                            <ul class="helpful-links">
                                <HelpfulLinks v-for="(item, index) in hilfreicheLinks" :key="item.id" :link="item" />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Resource from '@/api/resource';
import VueScrollTo from 'vue-scrollto';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
const contentResource = new Resource('contents');
import { getContentsOfType } from '@/api/content';
import { getFieldValues, showLoader, hideLoader, sortByProperty, normalizeString } from '@/utils/helpers';

export default {
    name: 'RatgeberCategoryIndex',
    mixins: [screenSizeMixin],
    components: {
        HelpfulLinks: () => import('./components/HelpfulLinks.vue'),
        Breadcrumb: () => import('@/components/layout/Breadcrumb.vue'),
        RatgeberCard: () => import('@/components/cards/Ratgeber.vue'),
    },
    data() {
        return {
            content: null,
            searchWord: '',
            contentLoader: null,
            categoryContentLoader: null,
            categoryContentList: null,
            awaitingSearch: false,
            isMobileView: false,
            dataSet: {
                total: 0,
                data: null,
                meta: null,
                query: {
                    page: 1,
                    limit: 500,
                    selectedAppId: this.$appId,
                    keyword: this.category,
                    meilisearch: '',
                    selectRandomly: true,
                    type: 'teaser',
                    selectedContentTypeId: this.$ratgeberId,
                    view_status: 1,
                    sort: {
                        prop: '',
                        order: 'asc'
                    },
                },
            },
            scrollVerticalOptions: {
                container: 'body',
                easing: 'ease-in',
                lazy: false,
                offset: -10,
                force: true,
                cancelable: true,
                x: false,
                y: true,
            },
        }
    },
    watch: {
        $screenWidth(newWidth) {
            if (newWidth <= 575) {
                this.isMobileView = true;
            }
            else {
                this.isMobileView = false;
            }
        },
        category(newId, oldId) {
            if (newId != oldId) {
                this.getContentsForCategory();
                this.$scrollTo();
            }
        },
        contentId(newId, oldId) {
            if (newId !== null) {
                this.getContent(this.contentId);
            }
        },
        searchWord(newWord, oldWord) {
            this.dataSet.query.meilisearch = newWord;
            if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.getContentsForCategory();
                    this.awaitingSearch = false;
                }, 1000); // 1 sec delay
            }
            this.awaitingSearch = true;
        },
    },
    created() {
        this.getContent(this.contentId);
        this.getContentsForCategory();
    },
    computed: {
        title() {
            return "Ratgeber | " + this.category;
        },
        slug() {
            return this.$route.params.slug;
        },
        category() {
            if (this.slug == "Kinder&Jugendliche") {
                return "Kinder & Jugendliche";
            }
            if (this.slug == "NeuinWolfsburg") {
                return "Neu in Wolfsburg";
            }
            return this.slug;
        },
        contentId() {
            switch (this.category) {
                case "Senior*innen":
                    return 232747;
                    break;
                case "Kinder & Jugendliche":
                    return 232749;
                    break;
                case "Neu in Wolfsburg":
                    return 232745;
                    break;
                case "Eltern":
                    return 232743;
                    break;
                case "Erwachsene":
                    return 398114;
                    break;
            }
            return null;
        },
        image() {
            switch (this.category) {
                case "Senior*innen":
                    return "/assets/icons/alter.svg";
                    break;
                case "Kinder & Jugendliche":
                    return "/assets/icons/KinderJugendliche.svg";
                    break;
                case "Neu in Wolfsburg":
                    return "/assets/icons/Integration.svg";
                    break;
                case "Eltern":
                    return "/assets/icons/Familie.svg";
                    break;
                case "Erwachsene":
                    return "/assets/icons/Erwachsene.svg";
                    break;
            }
            return;
        },
        contents() {
            if (this.dataSet.data != null && this.dataSet.data.length > 0) {
                /*hard-coded Langcode*/
                return this.dataSet.data.length > 0 ? this.dataSet.data : null;
            }
            return null;
        },
        subCat() {
            switch (this.category) {
                case "Senior*innen":
                    return "seniorinnen";
                    break;
                case "Kinder & Jugendliche":
                    return "kinder__jugendliche";
                    break;
                case "Neu in Wolfsburg":
                    return "neuzugewanderte";
                    break;
                case "Eltern":
                    return "eltern";
                    break;
                case "Erwachsene":
                    return "erwachsene";
                    break;
            }
            return;
        },
        hilfreicheLinks() {
            var value = getFieldValues(this.content, 'hilfreiche_links');
            if (value != null) {
                if (!Array.isArray(value)) {
                    return [value];
                }
                return value;
            }
            return null;
        },
    },
    methods: {
        getFieldValues,
        sortByProperty,
        showLoader,
        hideLoader,
        normalizeString,
        toggleGroup(index, group) {
            this.categoryContentList[index].collapsed = !group.collapsed;
        },
        scrollToAnchor(categoryAnchor) {
            var anchor = '#' + categoryAnchor;
            VueScrollTo.scrollTo(anchor, '2000', this.scrollVerticalOptions);
        },
        getCategoryDescription(category) {
            switch (category) {
                case "Neu in Wolfsburg":
                    return "Infos für Neubürger*innen und Geflüchtete.";
                    break;
                case "Senior*innen":
                    return "Tipps & Tricks für ein erfülltes Leben im Alter.";
                    break;
                case "Eltern":
                    return "Tipps & Ratschläge für Eltern und Erziehungsberechtige."
                    break;
                case "Kinder & Jugendliche":
                    return "Infos und Hilfestellungen für Kinder & Jugendliche.";
                    break;
                case "Erwachsene":
                    return "Praktische Infos für den Alltag im Erwachsenenleben.";
                    break;
            }
            return "";
        },
        getCategoryContentList() {
            if (this.dataSet.data !== null && this.dataSet.total > 0) {
                var contents = this.dataSet.data;
                /*we need to order the institutions into an array of objects by the first letter*/
                /*init*/
                var resultSet = [];
                var singleResultSet = {};
                var cat = null;

                for (var i = 0; i < contents.length; i++) {
                    var content = contents[i];
                    /*let's check if the current letter is the same as the first letter from the title of the institution*/
                    if (content.category != cat) {
                        /*check if the current letter is null. If that is not the case, we'll push the singleResultSet onto the resultSet*/
                        if (cat != null) {
                            resultSet.push(singleResultSet);
                        }

                        /*set the letter for the comparison*/
                        cat = content.category;

                        singleResultSet = {
                            category: content.category,
                            collapsed: false,
                            contents: [],
                        };
                    }

                    /*add the current institution to the singleResultSet*/
                    singleResultSet.contents.push(content);

                }
                /*at the end of the for-loop there is still one last singleResultSet that is not part of the overall result set.*/
                resultSet.push(singleResultSet);
                return resultSet;
            }
            return null;

        },
        getContent(id) {
            this.contentLoader = this.showLoader(this.contentLoader);
            contentResource.get(id)
                .then(response => {
                    this.content = response.data;
                })
                .finally(() => {
                    this.contentLoader = this.hideLoader(this.contentLoader);
                });
        },
        queryChanges() {
            if (this.dataSet.query.meilisearch !== '' && this.dataSet.query.meilisearch.length > 2) {
                this.getContent();
            }
        },
        getContentsForCategory() {
            this.categoryContentLoader = this.showLoader(this.categoryContentLoader);
            this.dataSet.query.keyword = '"' + this.category + '"';
            const { limit, page } = this.dataSet.query;
            getContentsOfType(this.$ratgeberId, this.dataSet.query).then(response => {
                const { data, meta } = response;
                this.dataSet.data = data;
                this.dataSet.data.forEach((element, index) => {
                    element['index'] = (page - 1) * limit + index + 1;
                });
                this.dataSet.meta = meta;
                this.dataSet.total = meta.total;
                this.enhanceContentAttribsWithCategory();
                this.sortContentsByCategories();
                this.categoryContentList = this.getCategoryContentList();
            })
                .finally(() => {
                    this.categoryContentLoader = this.hideLoader(this.categoryContentLoader);
                });
        },
        enhanceContentAttribsWithCategory() {
            for (var k = 0; k < this.dataSet.data.length; k++) {
                var content = this.dataSet.data[k];
                /*get the categories from this content*/
                var cats = getFieldValues(content, this.subCat);
                /*check if there is a value in the cats*/
                if (cats != null && cats.length > 0) {
                    /*is cats an array?*/
                    if (Array.isArray(cats)) {
                        content.category = cats[0];
                    }
                    else {
                        content.category = cats;
                    }
                }
            }
        },
        sortContentsByCategories() {
            return sortByProperty(this.dataSet.data, 'category', 'de');
        },
    }
}
</script>
<style lang="scss" scoped>
@import '../../scss/_variables.scss';


.group-box {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .collapsible-button {
        display: none;
        cursor: pointer;

        @media (max-width: 767px) {
            display: block;
        }
    }

}

.group-label {
    display: none;

    @media (min-width: 576px) {
        display: block;
    }
}

.anchor-box {
    padding: 20px;
    max-width: 380px;
    background: #F9F9F9;
    box-shadow: 1px 2px 10px 1px #00000040;

    .anchor-links {
        margin-top: 20px;

        li {
            line-height: 40px;

            a {
                color: $primary;

                /*&::after {
              margin-left: 10px;
              font-family: 'Material Icons';
              content: '\e6b8';
              font-size: 20px;
              vertical-align: top;
          }*/
            }
        }
    }
}


.ratgeber-in-category {
    width: 100%;
    margin: 0px !important;
    margin-bottom: 20px !important;

    @media (max-width: 1400px) {
        min-width: auto !important;
        max-width: auto !important;
    }

    @media (max-width: 991px) {
        max-width: 100% !important;
    }

    @media (max-width: 800px) {
        margin: 10px !important;
    }

    @media (max-width: 440px) {
        margin-right: 0px !important;
    }

    &:first-child {
        margin-left: 0px !important;
    }
}
</style>